import React, { useEffect, useRef, useState } from "react";
import { LANGUAGE_PAIR } from "../../../../../../constants/LANGUAGE_PAIR";
import { useDispatch, useSelector } from "react-redux";
import { postAuthData } from "../../../../../../helpers/request";
import {
  resetConsultNewReqForm,
  setConsultNewReqForm,
  setModal,
} from "../../../../../../store/utilsDataSlice/utilsDataSlice";
import TOOLS_MODALS from "../../../../../ToolsConstants/TOOLS_MODALS";
import toast from "react-hot-toast";
import { updateFileDownloadLink } from "../../../../../../store/consultgptDataSlice/consultgptDataSlice";
import "./ToolsConsultPdfPreviewDropdown.scss";

const ToolsConsultPdfPreviewDropdown = ({ popbtn = false }) => {
  const { file_preview } = useSelector((state) => state.consultGptData);
  const dispatch = useDispatch();
  const [isDownloadDropdownOpen, setIsDownloadDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);
  const dropdownIconRef = useRef(null);

  const handleToggleDropdown = () => {
    setIsDownloadDropdownOpen((prev) => !prev);
  };

  // useEffect(() => {
  //   console.log(file_preview.reference_file_id);
  // }, [file_preview]);
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) &&
        dropdownIconRef.current &&
        !dropdownIconRef.current.contains(event.target)
      ) {
        setIsDownloadDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [dropdownRef, dropdownIconRef]);

  // function for fetching consult file data against refrence id and opening new req form with prepopulated data
  const fetchConsultDataAndOpenModal = async (file_id, dispatch) => {
    try {
      const res = await postAuthData(
        `${process.env.REACT_APP_CONSULT_URL}/api-client/tools/v1/file/detail/`,
        { file_id },
      );

      if (res?.success) {
        const source_language = res?.data?.source_language;
        let languagePair = "";

        switch (source_language) {
          case "english":
            languagePair = LANGUAGE_PAIR.ENGLISH;
            break;
          case "arabic":
            languagePair = LANGUAGE_PAIR.ARABIC;
            break;
          default:
            languagePair = LANGUAGE_PAIR.NONE;
        }

        const consult_instruction = `I want to get this file translated with EZ Assured Language Pair: ${languagePair}`;

        const new_req_data = {
          consult_s3_file_path: res?.data?.file_path,
          consult_file_name: res?.data?.file_name,
          consult_instruction,
        };

        dispatch(setConsultNewReqForm(new_req_data));
        dispatch(setModal(TOOLS_MODALS.NEW_REQ));
      } else {
        toast.error(res?.message || "Error fetching data");
        console.error("Error Fetching Data");
        dispatch(resetConsultNewReqForm());
        dispatch(setModal(TOOLS_MODALS.NEW_REQ));
      }
    } catch (err) {
      console.error(err?.message || "something went wrong!!");
      toast.error("Something went wrong!!");
      dispatch(resetConsultNewReqForm());
      dispatch(setModal(TOOLS_MODALS.NEW_REQ));
    }
  };

  const handleUpdateDownloadLink = async (e, file_id) => {
    e.stopPropagation();
    dispatch(updateFileDownloadLink({ file_id }));
  };

  const handleOpenRequestModal = () => {
    setIsDownloadDropdownOpen(false);
    fetchConsultDataAndOpenModal(file_preview?.reference_file_id, dispatch);
  };
  return (
    <div className="tool_consult_file_donwload_dropdown">
      {/* {popbtn && (
        <button className="tool_consult_file_donwload_dropdown_btn">
          <img
            src={
              process.env.PUBLIC_URL +
              `${"/resources/consult_file_status_icon/translate_btn.svg"}`
            }
          />
          <span>العربية</span>
        </button>
      )} */}
      {/* <button className="tool_consult_file_donwload_dropdown_btn">
        <FiDownload />
        <span>Download</span>
      </button> */}
      <img
        onClick={handleToggleDropdown}
        src={
          process.env.PUBLIC_URL +
          ` ${
            isDownloadDropdownOpen
              ? "/resources/consult_file_status_icon/dropdown_icon_select.svg"
              : "/resources/consult_file_status_icon/DownloadButton.svg"
          }`
        }
        alt="download btn"
        className="tool_consult_file_donwload_img"
        ref={dropdownIconRef}
      />
      {isDownloadDropdownOpen && (
        <div
          className="tool_consult_file_donwload_dropdown_wrapper"
          ref={dropdownRef}
        >
          <span
            className="tool_consult_file_donwload_dropdown_text"
            onClick={(e) => {
              handleUpdateDownloadLink(e, file_preview?.reference_file_id);
            }}
          >
            Download Original File
          </span>
          <div
            className="tool_consult_file_donwload_dropdown_text"
            onClick={handleOpenRequestModal}
          >
            Translate with EZ Assured
          </div>
        </div>
      )}
    </div>
  );
};

export default ToolsConsultPdfPreviewDropdown;
