import React, { useEffect, useRef, useState } from "react";
import "./ConsultChatForm.scss";
import { IoMdSend } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import {
  addMessage,
  createNewChat,
  handleChatDisbale,
  setInitialMessageSend,
  setSocket,
  socketConnection,
  startNewConsultChat,
  updateChatCurrentFlow,
  updateLastestMessageId,
  updateMessageChildren,
} from "../../../../../store/consultgptDataSlice/consultgptDataSlice";
import { v4 as uuidv4 } from "uuid";
import {
  CHAT_MESSAGE_TYPE,
  CHAT_TYPE,
  getLastParentId,
} from "../../../../../store/consultgptDataSlice/common";
import toast from "react-hot-toast";
import { validateChatMessage } from "../../../../../utils/validateChatMessage";
import { setModal } from "../../../../../store/utilsDataSlice/utilsDataSlice";
import TOOLS_MODALS from "../../../../ToolsConstants/TOOLS_MODALS";
// import SpeechRecognition, {
//   useSpeechRecognition,
// } from "react-speech-recognition";

const ConsultChatForm = () => {
  const { socket, chatCreation, chat_data } = useSelector(
    (state) => state.consultGptData,
  );
  const dispatch = useDispatch();
  const [message, setMessage] = useState("");
  const [messageQueue, setMessageQueue] = useState([]);
  // const timeoutRef = useRef(null);
  // const speechTimeoutRef = useRef(null);
  // const lastProcessedRef = useRef("");
  const inputRef = useRef(null);
  // const { transcript, listening, resetTranscript } = useSpeechRecognition();

  const toggleChatDisable = (disable) => {
    dispatch(handleChatDisbale(disable));
  };

  const handleShowQueryLimitModal = () => {
    dispatch(setModal(TOOLS_MODALS.CHAT_QUERY_LIMIT));
  };

  useEffect(() => {
    if (socket && messageQueue.length) {
      processMessageQueue();
    }
  }, [socket, messageQueue]);

  const handleSendMessage = async (e) => {
    e.preventDefault();
    dispatch(handleChatDisbale(true));

    if (!validateMessage()) {
      resetMessage();
      return;
    }

    const { sendMessageObj, messageObj, parentMessageId, currentMessageId } =
      createMessageObjects();

    if (message.trim()) {
      try {
        if (isNewConsultChat()) {
          await startNewChat();
        }

        if (socket) {
          emitMessage(messageObj);
          addMessageToState(sendMessageObj, parentMessageId, currentMessageId);
        } else {
          queueMessage({
            messageObj,
            sendMessageObj,
            parentMessageId,
            currentMessageId,
          });
        }
        finalizeSendMessage();
      } catch (error) {
        console.error("Error sending message:", error);
        dispatch(handleChatDisbale(false));
      }
    } else {
      showEmptyMessageError();
      dispatch(handleChatDisbale(false));
    }
  };

  const validateMessage = () => {
    return validateChatMessage(
      message,
      chat_data,
      toggleChatDisable,
      handleShowQueryLimitModal,
    );
  };

  const resetMessage = () => {
    setMessage("");
  };

  const createMessageObjects = () => {
    const currentMessageId = uuidv4();
    const childMessageId = uuidv4();
    const timestamp = Date.now();
    const parentMessageId = getLastParentId(
      chat_data.currentChatFlow,
      chat_data.messages,
    );

    const sendMessageObj = {
      uuid: currentMessageId,
      parent_message: parentMessageId,
      child_message: [childMessageId],
      type: CHAT_MESSAGE_TYPE.USER,
      content: message,
      timestamp: timestamp,
    };

    const messageObj = {
      query_type: "new",
      parent_node_id: parentMessageId,
      current_node_id: currentMessageId,
      child_node_id: childMessageId,
      message_content: message,
      timestamp: timestamp,
    };

    return {
      sendMessageObj,
      messageObj,
      parentMessageId,
      currentMessageId,
    };
  };

  const isNewConsultChat = () => {
    return (
      chatCreation?.isNewChat &&
      chat_data.chat_type === CHAT_TYPE.TALK_TO_CONSULT
    );
  };

  const startNewChat = async () => {
    const response = await dispatch(
      startNewConsultChat({ chat_id: chat_data.root_node_id }),
    );
    if (response.meta.requestStatus === "fulfilled") {
      const socketResponse = await dispatch(
        socketConnection({ chat_id: chat_data.root_node_id }),
      );
      const socket = socketResponse.payload;
      dispatch(setSocket(socket));
      dispatch(createNewChat(false));
      dispatch(setInitialMessageSend(true));
    } else {
      setMessage("");
      toast.error("Failed to start new chat, try again!");
      throw new Error("Failed to start new chat");
    }
  };

  const emitMessage = (messageObj) => {
    if (socket) {
      socket.emit("query", messageObj);
    } else {
      throw new Error("Socket not initialized");
    }
  };

  const addMessageToState = (
    sendMessageObj,
    parentMessageId,
    currentMessageId,
  ) => {
    dispatch(addMessage(sendMessageObj));
    dispatch(updateLastestMessageId(sendMessageObj?.uuid));
    dispatch(
      updateMessageChildren({
        parentId: parentMessageId,
        newChildId: currentMessageId,
      }),
    );
    dispatch(updateChatCurrentFlow([sendMessageObj.uuid]));
  };

  const finalizeSendMessage = () => {
    setMessage("");
  };

  const showEmptyMessageError = () => {
    toast.error("Can't send empty message", { id: "empty_message" });
  };

  const queueMessage = (messageData) => {
    setMessageQueue((prevQueue) => [...prevQueue, messageData]);
  };

  const processMessageQueue = () => {
    messageQueue.forEach(
      ({ messageObj, sendMessageObj, parentMessageId, currentMessageId }) => {
        emitMessage(messageObj);
        addMessageToState(sendMessageObj, parentMessageId, currentMessageId);
      },
    );
    setMessageQueue([]);
  };

  const handleChatInputChange = (e) => {
    setMessage(e.target.value);
  };

  // const handleStartListening = () => {
  //   resetTimeout();
  //   SpeechRecognition.startListening({ continuous: true, language: "en" });
  // };

  // const stopListening = () => {
  //   clearTimeout(timeoutRef.current);
  //   SpeechRecognition.stopListening();
  //   resetTranscript();
  // };

  // const resetTimeout = () => {
  //   clearTimeout(timeoutRef.current);
  //   timeoutRef.current = setTimeout(() => {
  //     console.log("No speech detected for 3 seconds. Stopping...");
  //     stopListening();
  //   }, 3000);
  // };

  // useEffect(() => {
  //   if (listening && transcript.trim()) {
  //     resetTimeout();
  //   }
  // }, [transcript, listening]);

  // const handleDebouncedProcessing = () => {
  //   clearTimeout(speechTimeoutRef.current);
  //   speechTimeoutRef.current = setTimeout(() => {
  //     if (transcript && transcript !== lastProcessedRef.current) {
  //       const newText = transcript.replace(lastProcessedRef.current, "").trim();

  //       if (newText) {
  //         setMessage((prevMessage) => (prevMessage + " " + newText).trim());
  //       }
  //       // Updating the ref with the latest transcript to avoid duplication
  //       lastProcessedRef.current = transcript;
  //     }
  //   }, 500);
  // };

  // useEffect(() => {
  //   if (transcript) {
  //     handleDebouncedProcessing();
  //   }
  // }, [transcript]);

  // useEffect(() => {
  //   if (inputRef.current) {
  //     inputRef.current.focus();
  //     inputRef.current.setSelectionRange(message.length, message.length); // Move cursor to end
  //   }
  // }, [message]);

  return (
    <div className="tools_consult_chat_footer_wrapper">
      <div className="tools_consult_chat_input_form">
        <span htmlFor="queries_input" className="tools_consult_form_label_text">
          Query
        </span>
        <form
          onSubmit={handleSendMessage}
          className={`tools_consult_message_form ${
            chat_data.is_chat_input_disabled ? "disabled" : ""
          }`}
        >
          <input
            id="queries_input"
            placeholder="Talk to Consult.ai"
            className={`tools_consult_message_input ${
              chat_data.is_chat_input_disabled ? "disabled" : ""
            }`}
            value={message}
            onChange={handleChatInputChange}
            autoComplete="off"
            ref={inputRef}
            disabled={chat_data.is_chat_input_disabled}
            title={
              chat_data.is_chat_input_disabled ? "Chat input is disabled" : ""
            }
          />
          {/* {listening && (
            <span style={{ fontStyle: "italic" }}>Listening...</span>
          )} */}
          {/* <button
            type="button"
            className="tools_consult_message_input_btn_wrapper"
            onClick={handleStartListening}
            disabled={chat_data.is_chat_input_disabled}
          >
            <img
              src={
                process.env.PUBLIC_URL +
                "/resources/consult_file_status_icon/microphone.svg"
              }
              className="tools_consult_message_input_btn_icon"
            />
            {listening && (
              <div className="tools_consult_message_input_listening_dots">
                <span></span>
                <span></span>
                <span></span>
              </div>
            )}
          </button> */}
          <button
            type="submit"
            className="tools_consult_message_input_btn_wrapper"
            disabled={chat_data.is_chat_input_disabled}
          >
            <IoMdSend className="tools_consult_message_input_btn_icon" />
          </button>
        </form>
      </div>
      <div className="tools_consult_gpt_desc_wrapper">
        <span className="tools_consult_gpt_desc_text">
          The accuracy of this AI is around 95%. Fact-check important
          information.
        </span>
      </div>
    </div>
  );
};

export default ConsultChatForm;
