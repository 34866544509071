import React, { useEffect, useState } from "react";
import { Viewer, Worker } from "@react-pdf-viewer/core";
import { RxZoomIn } from "react-icons/rx";
import { useDispatch, useSelector } from "react-redux";
import "./PdfPreview.scss";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/page-navigation/lib/styles/index.css";
import TOOLS_MODALS from "../../../../../ToolsConstants/TOOLS_MODALS";
import { setModal } from "../../../../../../store/utilsDataSlice/utilsDataSlice";

const PdfCustomPageLayer = ({ renderPageProps, initialPage, popbtn }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    // Mark the page as rendered when the canvas layer is rendered
    if (renderPageProps.canvasLayerRendered) {
      renderPageProps.markRendered(renderPageProps.pageIndex);
    }
  }, [renderPageProps.canvasLayerRendered]);

  return (
    <>
      {renderPageProps.canvasLayer.children}
      {(initialPage === 0
        ? renderPageProps.pageIndex === 0
        : renderPageProps.pageIndex + 1 === initialPage) &&
        popbtn && (
          <div className="pdf_page_pop_up_reference_page_wrapper">
            <button
              className="pdf_page_pop_up_btn"
              onClick={() =>
                dispatch(setModal(TOOLS_MODALS.CONSULT_PDF_PREVIEW_POP_UP))
              }
            >
              <RxZoomIn className="pdf_page_pop_up_btn_icon" />
            </button>
          </div>
        )}
    </>
  );
};

const renderPageDocs = (props, initialPage, popbtn) => (
  <PdfCustomPageLayer
    renderPageProps={props}
    initialPage={initialPage}
    popbtn={popbtn}
  />
);

const PdfPreview = ({ popbtn = false }) => {
  const { file_preview } = useSelector((state) => state.consultGptData);
  const [previewFileLoaded, setPreviewFileLoaded] = useState(false);

  const initialPage =
    file_preview?.reference_page !== undefined
      ? Math.max(file_preview?.reference_page, 1) - 1
      : 0;

  return (
    <div className="rpv-core__viewer tools_consult_pdf_container">
      {!previewFileLoaded && popbtn && (
        <div className="file_preview_downloading">Loading preview!</div>
      )}
      <div className="tools_consult_pdf_wrapper">
        {file_preview?.file_preview_url && (
          <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js">
            <Viewer
              fileUrl={file_preview?.file_preview_url}
              initialPage={initialPage}
              onDocumentLoad={() => setPreviewFileLoaded(true)}
              renderPage={(props) => renderPageDocs(props, initialPage, popbtn)}
            />
          </Worker>
        )}
      </div>
    </div>
  );
};

export default PdfPreview;
