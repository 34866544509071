import React, { useEffect, useRef, useState } from "react";
import { lazy_load_image } from "../../../../..";
import { useDispatch, useSelector } from "react-redux";
import {
  getFileDownloadLink,
  sendConsultResponseFeedback,
  toggleShowConsultFilePane,
  // translateTextMessage,
  // updateFileDownloadLink,
  updatePreviewReferenceData,
} from "../../../../../store/consultgptDataSlice/consultgptDataSlice";
import {
  CHAT_MESSAGE_TYPE,
  MESSAGE_FEEDBACK_TYPE,
} from "../../../../../store/consultgptDataSlice/common";
import ThumbsUp from "../../../../../Icons/ToolsConsultIcons/ThumbsUp";
import ThumbsDown from "../../../../../Icons/ToolsConsultIcons/ThumbsDown";
import MessageFeedbackForm from "./MessageFeedbackForm/MessageFeedbackForm";
import { IoMdArrowForward } from "react-icons/io";
import { extractFileNameAndExtension } from "../../../../../utils/extractFileNameAndExtension";

const OutputReference = ({ content, dispatch, file_preview }) => {
  const [viewMoreReference, setViewMoreReference] = useState(false);
  const [sourcePreviews, setSourcePreviews] = useState([]);
  const [activeDropdownIndex, setActiveDropdownIndex] = useState(null);
  const dropdownRef = useRef(null);

  const hanleOpenFilePreview = (data, content, index) => {
    dispatch(toggleShowConsultFilePane(true));
    if (data?.file_identifier === file_preview?.reference_file_id) return;
    dispatch(getFileDownloadLink({ file_id: data?.file_identifier }));
    dispatch(
      updatePreviewReferenceData({
        reference_id: data?.file_identifier,
        reference_page: data?.page_number,
        reference_text: data?.source_text,
        message_id: content?.uuid,
        reference_index: index,
        reference_file_name: data?.file_name,
      }),
    );
  };

  const isActiveReference = (data, content, index) => {
    if (!data) return false;
    return (
      file_preview.reference_file_id === data.file_identifier &&
      file_preview.reference_page === data.page_number &&
      file_preview.reference_msg_id === content.uuid &&
      file_preview.reference_index === index
    );
  };

  useEffect(() => {
    setSourcePreviews(content.slice(0, getNumberOfPreviewsToShow()));
  }, [content]);

  const getNumberOfPreviewsToShow = () => {
    // if (window.innerWidth <= 500) return 1;
    // if (window.innerWidth <= 700) return 2;
    return 3;
  };

  const handleSeeMoreBtn = () => {
    setSourcePreviews(content);
    setViewMoreReference(true);
  };

  const previewsToShowInitially = getNumberOfPreviewsToShow();
  const hasMorePreviews = content.length > previewsToShowInitially;

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setActiveDropdownIndex(null);
    }
  };

  useEffect(() => {
    if (activeDropdownIndex !== null) {
      document.addEventListener("click", handleClickOutside);
    } else {
      document.removeEventListener("click", handleClickOutside);
    }
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [activeDropdownIndex]);

  // const maskSourceName = (sourceName) => {
  //   if (!sourceName || sourceName.length <= 8) return sourceName;
  //   const firstPart = sourceName.slice(0, 4);
  //   const lastPart = sourceName.slice(-4);
  //   const maskedPart = "*".repeat(sourceName.length - 8); // Mask middle part
  //   return (
  //     <>
  //       <span className="first_part">{firstPart}</span>
  //       <span className="masked_middle">{maskedPart}</span>
  //       <span className="last_part">{lastPart}</span>
  //     </>
  //   );
  // };

  return (
    <>
      <div className="tools_consult_sender_reference_source_file_previews_wrapper">
        {sourcePreviews.map((data, index) => {
          const { name, extension } = extractFileNameAndExtension(
            data?.file_name || "",
          );
          return (
            <div
              className={`${
                isActiveReference(data, content, index)
                  ? "tools_consult_sender_active_reference_content_wrapper"
                  : ""
              } tools_consult_sender_reference_file_preview`}
              onClick={() => hanleOpenFilePreview(data, content, index)}
              key={index}
            >
              <div className="tools_consult_bot_message_reference_header_wrapper">
                {/* <img
                  className="tools_consult_bot_message_reference_type_image"
                  src={`${
                    data?.reference_type === RESPONSE_REFRENCE_TYPE.FILE
                      ? process.env.PUBLIC_URL +
                        "/resources/consult_file_status_icon/pdf_icon.svg"
                      : "/resources/consult_file_status_icon/website_icon.svg"
                  }`}
                  alt="reference_img"
                /> */}
                <img
                  className="tools_consult_bot_message_reference_type_image"
                  src="/resources/consult_file_status_icon/pdf_icon.svg"
                  alt="pdf"
                />
                <div className="tools_consult_bot_message_reference_header_text_wrapper">
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "/resources/consult_file_status_icon/quote_icon.svg"
                    }
                    alt="quote"
                    className="tools_consult_bot_message_reference_quote_icon"
                  />
                  {data?.source_text && (
                    <span className="tools_consult_bot_message_reference_text">
                      &quot;...{data.source_text}...&quot;
                    </span>
                  )}
                </div>
              </div>
              {/* {data?.reference_type === RESPONSE_REFRENCE_TYPE.FILE ? ( */}
              <div className="tools_consult_bot_message_reference_file_data">
                <div className="tools_consult_bot_message_reference_file_name_wrapper">
                  <span className="tools_consult_bot_message_reference_file_name">
                    {name}
                  </span>
                  {extension && (
                    <span className="tools_consult_bot_message_reference_file_extension">
                      .{extension}
                    </span>
                  )}
                </div>
                <span className="tools_consult_bot_message_reference_file_page_count">
                  [p. {data?.page_number}]
                </span>
              </div>
              {/* ) : (
                <div className="tools_consult_bot_message_reference_weblink">
                  {maskSourceName(data?.source_name)}
                </div>
              )} */}
            </div>
          );
        })}
        {hasMorePreviews && !viewMoreReference && (
          <div className="tools_consult_sender_reference_file_preview_see_more_btn_wrapper">
            <button
              className="tools_consult_sender_reference_file_preview_see_more_btn"
              onClick={handleSeeMoreBtn}
            >
              <IoMdArrowForward />
              Load More
            </button>
          </div>
        )}
      </div>
      {hasMorePreviews && !viewMoreReference && (
        <div className="tools_consult_sender_reference_file_preview_see_more_btn_wrapper_mobile">
          <button
            className="tools_consult_sender_reference_file_preview_see_more_btn"
            onClick={handleSeeMoreBtn}
          >
            <IoMdArrowForward />
            Load More
          </button>
        </div>
      )}
    </>
  );
};

const ResponeFeedback = ({ content, dispatch, chat_data, feedback }) => {
  const handleSendPositiveFeedback = (feedback) => {
    dispatch(
      sendConsultResponseFeedback({
        chat_id: chat_data.root_node_id,
        message_id: content?.uuid,
        feedback: feedback,
        id: null,
      }),
    );
  };

  const handleSendNegativeFeedback = (feedback, id) => {
    dispatch(
      sendConsultResponseFeedback({
        chat_id: chat_data.root_node_id,
        message_id: content?.uuid,
        feedback: feedback,
        id: id,
      }),
    );
  };

  return (
    <div className="tools_consult_helpful_texts">
      <span>Was this helpful?</span>
      <div className="tools_consult_feedback_btn_wrapper">
        <button
          className={` ${
            content?.feedback === MESSAGE_FEEDBACK_TYPE.POSITIVE
              ? "tool_consult_feedback_thumb_selected"
              : "tool_consult_feedback_thumb_btn"
          } ${
            content?.feedback === MESSAGE_FEEDBACK_TYPE.NEGATIVE
              ? "cursor_not_allowed"
              : ""
          }`}
          onClick={() =>
            handleSendPositiveFeedback(
              content?.feedback === MESSAGE_FEEDBACK_TYPE.POSITIVE
                ? MESSAGE_FEEDBACK_TYPE.NEUTRAL
                : MESSAGE_FEEDBACK_TYPE.POSITIVE,
            )
          }
          disabled={
            content?.feedback === MESSAGE_FEEDBACK_TYPE.NEGATIVE ||
            feedback.loading
          }
        >
          <ThumbsUp />
        </button>

        <button
          className={` ${
            content?.feedback === MESSAGE_FEEDBACK_TYPE.NEGATIVE
              ? "tool_consult_feedback_thumb_selected"
              : "tool_consult_feedback_thumb_btn"
          } ${
            content?.feedback === MESSAGE_FEEDBACK_TYPE.NEGATIVE
              ? "cursor_not_allowed"
              : ""
          }`}
          onClick={() =>
            handleSendNegativeFeedback(
              content?.feedback === MESSAGE_FEEDBACK_TYPE.NEGATIVE
                ? MESSAGE_FEEDBACK_TYPE.NEUTRAL
                : MESSAGE_FEEDBACK_TYPE.NEGATIVE,
              content?.uuid,
            )
          }
          disabled={
            content?.feedback === MESSAGE_FEEDBACK_TYPE.NEGATIVE ||
            feedback.loading
          }
        >
          <ThumbsDown />
        </button>
      </div>
    </div>
  );
};

const BotMessage = ({ content }) => {
  // const [showTranslated, setShowTranslated] = useState(false);
  const { chat_data, file_preview, MessagefeedbackForm, feedback } =
    useSelector((state) => state.consultGptData);
  const dispatch = useDispatch();

  // const handleGetTranslation = (content) => {
  //   if (showTranslated) {
  //     setShowTranslated(false);
  //   } else {
  //     if (content?.translated_content && content.translated_content !== "") {
  //       setShowTranslated(true);
  //     } else {
  //       dispatch(
  //         translateTextMessage({
  //           chat_id: chat_data.root_node_id,
  //           message_id: content?.uuid,
  //         }),
  //       )
  //         .then(() => {
  //           setShowTranslated(true);
  //         })
  //         .catch((error) => {
  //           console.error(error);
  //         });
  //     }
  //   }
  // };

  const isGreetingMessage =
    chat_data.currentChatFlow.find(
      (messageId) =>
        chat_data.messages[messageId]?.type === CHAT_MESSAGE_TYPE.BOT,
    ) === content?.uuid;

  return (
    <div
      className="tools_consult_sender_message_wrapper"
      key={content?.uuid}
      id={content?.uuid}
    >
      <div className="tools_consult_image_wrapper">
        <img
          onLoad={lazy_load_image}
          src={process.env.PUBLIC_URL + "/consult_profile_img.svg"}
          alt="consult.ai"
          className="tools_consult_image"
        />
      </div>
      <div className="tools_consult_sender_message_outer_container">
        <div className="tools_consult_sender_message_container">
          <div className="tools_consult_sender_name_text_wrapper">
            <span>Consult.ai</span>
          </div>
          <div className="tools_consult_sender_text_message_wrapper">
            <div className="tools_consult_sender_text_message">
              {/* <div
                dir={showTranslated ? "rtl" : "ltr"}
                dangerouslySetInnerHTML={{
                  __html: showTranslated
                    ? content?.translated_content
                    : content?.content,
                }}
              ></div> */}
              <div
                dangerouslySetInnerHTML={{
                  __html: content?.content,
                }}
              ></div>
            </div>
            {/* <button
              className="tools_consult_text_translate"
              onClick={() => handleGetTranslation(content)}
            >
              <img
                onLoad={lazy_load_image}
                src={process.env.PUBLIC_URL + "/en_to_ar_translate_icon.svg"}
                alt="consult.ai"
                className="tools_consult_translate_image"
              />
            </button> */}
          </div>
          {/* {content?.output_references && (
            <OutputReference
              content={content}
              dispatch={dispatch}
              file_preview={file_preview}
            />
          )} */}
          {content?.output_references && (
            <OutputReference
              content={content?.output_references}
              dispatch={dispatch}
              file_preview={file_preview}
            />
          )}
        </div>
        {!isGreetingMessage && (
          <ResponeFeedback
            content={content}
            dispatch={dispatch}
            chat_data={chat_data}
            feedback={feedback}
          />
        )}
        {MessagefeedbackForm.feedbackData.isFeedbackFormActive &&
          MessagefeedbackForm.feedbackData.activeMessageId ===
            content?.uuid && <MessageFeedbackForm />}
      </div>
    </div>
  );
};

export default BotMessage;
