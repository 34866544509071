import React from "react";
import "./ConsultPdfPreviewPopUpModal.scss";
import { IoCloseSharp } from "react-icons/io5";
import TOOLS_MODALS from "../../../ToolsConstants/TOOLS_MODALS";
import { setModal } from "../../../../store/utilsDataSlice/utilsDataSlice";
import { useDispatch, useSelector } from "react-redux";
import PdfPreview from "../../../ToolsComponents/ToolConsulGptChatSection/ToolsConsultGptFilepane/ToolsConsultGptPdfPreview/PdfPreview/PdfPreview";
import ToolsConsultPdfPreviewDropdown from "../../../ToolsComponents/ToolConsulGptChatSection/ToolsConsultGptFilepane/ToolsConsultGptPdfPreview/ToolsConsultPdfPreviewDropdown/ToolsConsultPdfPreviewDropdown";

const ConsultPdfPreviewPopUpModal = () => {
  const { file_preview } = useSelector((state) => state.consultGptData);
  const dispatch = useDispatch();

  return (
    <section className="consult_pdf_preview_pop_up_modal">
      <div className="consult_pdf_preview_modal_wrapper">
        <div className="consult_pdf_preview_modal_header_wrapper">
          <div className="file_preview_head">
            {file_preview?.reference_file_name && (
              <div className="file_preview_heading">
                <img
                  src={
                    process.env.PUBLIC_URL +
                    "/resources/consult_file_status_icon/pdf_icon.svg"
                  }
                  alt="pdf_icon"
                  className="consult_pdf_preview_modal_file_icon"
                />
                <span>{file_preview?.reference_file_name}</span>
              </div>
            )}
            <ToolsConsultPdfPreviewDropdown popbtn={true} />
          </div>
        </div>
        <div className="consult_pdf_preview_modal_pdf_preview_wrapper">
          <PdfPreview />
        </div>
      </div>
      <div className="consult_pdf_preview_pop_up_modal_close">
        <IoCloseSharp
          onClick={() => dispatch(setModal(TOOLS_MODALS.NONE))}
          size={32}
        />
      </div>
    </section>
  );
};

export default ConsultPdfPreviewPopUpModal;
