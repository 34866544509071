import React from "react";
import "./ToolsConsultGptPdfPreview.scss";
import { useSelector } from "react-redux";
import fileNameLimiter from "../../../../../utils/fileNameLimiter";
import ToolsConsultPdfPreviewDropdown from "./ToolsConsultPdfPreviewDropdown/ToolsConsultPdfPreviewDropdown";
import PdfPreview from "./PdfPreview/PdfPreview";

const ToolsConsultGptPdfPreview = () => {
  const { file_preview } = useSelector((state) => state.consultGptData);

  return (
    <section className="tools_consult_files_preview_section">
      <div className="file_preview_container file_source_preview_container">
        <div className="file_preview_head">
          {file_preview?.reference_file_name && (
            <div className="file_preview_heading">
              {fileNameLimiter(file_preview?.reference_file_name)}
            </div>
          )}
          <ToolsConsultPdfPreviewDropdown popbtn={true} />
        </div>
        <div className="file_preview">
          <PdfPreview popbtn={true} />
        </div>
      </div>
    </section>
  );
};

export default ToolsConsultGptPdfPreview;
