export const extractFileNameAndExtension = (fullFileName) => {
  if (!fullFileName) return { name: "", extension: "" };

  const lastDotIndex = fullFileName.lastIndexOf(".");

  if (lastDotIndex === -1) {
    return { name: fullFileName, extension: "" }; // No extension found
  }

  const name = fullFileName.slice(0, lastDotIndex);
  const extension = fullFileName.slice(lastDotIndex + 1);

  return { name, extension };
};
